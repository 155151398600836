import React, { useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import "react-image-gallery/styles/css/image-gallery.css";
import { CDN_BASE_URL } from "../config";

const ProductGallery = ({
  images,
  currentIndex,
  setCurrentIndex,
  ImageGallery,
  setImageGallery,
}) => {
  useEffect(() => {
    if (!ImageGallery.length) {
      images?.map((item) =>
        setImageGallery((c) => [
          ...c,
          {
            original: CDN_BASE_URL + item,
            thumbnail: CDN_BASE_URL + item,
          },
        ])
      );
    }
  }, [images]);

  return (
    <div className=" relative lg:p-8">
      {ImageGallery.length > 1 && (
        <ReactImageGallery startIndex={currentIndex} items={ImageGallery} />
      )}
    </div>
  );
};

export default ProductGallery;
