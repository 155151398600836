import React from "react";
import CategoryCard from "./common/CategoryCard";
import { useTranslation } from "react-i18next";
import Reveal from "motion/Reveal";

const CategoryTitle = ({ sub, data, title }) => {
  const [t] = useTranslation("global");

  return (
    <div className="container-xxl py-6 border-b">
      <h1 className="text-[22px] lg:text-[32px] font-bold ">{title}</h1>
      {!sub && (
        <>
          <div className="flex flex-wrap gap-3 my-3">
            {data?.map((item) => (
              <Reveal>
                <CategoryCard t={t} data={item} prev={title?.toLowerCase()} />
              </Reveal>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryTitle;
