import { httpReauest } from "api/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaInstagram, FaTelegram, FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const [t] = useTranslation("global");
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const res = await httpReauest("GET", "/category", {}, {});
    setData(res.data.data);
  }
  return (
    <>
      <footer class="bg-[#3d3c38]  network_bg relative">
        <div class="px-[12px] text-[14px] py-8 text-center container-xxl text-white/80 z-10 relative">
          <div class="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4 ">
            <div>
              <h6 class="mb-3  font-semibold text-white text-[18px]  md:justify-start">
                EMPORIUM POINT
              </h6>
              <p>{t("slider-desc")}</p>
            </div>
            <div>
              <h6 class="mb-3  font-semibold text-white text-[18px]  md:justify-start">
                {t("category")}
              </h6>
              {data?.map((item) => (
                <p class="mb-3">
                  <Link to={"/category/" + item?.url}>{item.name}</Link>
                </p>
              ))}
            </div>
            <div>
              <h6 class="mb-3  font-semibold text-white text-[18px]  md:justify-start">
                {t("menu-about")}
              </h6>
              <p class="mb-3">
                <Link to={"/about"}> {t("menu-about")}</Link>
              </p>
              <p class="mb-3">
                <Link to={"/contact"}> {t("menu-contact")}</Link>
              </p>
              <p class="mb-3">
                <Link to={"/terms"}> {t("menu-terms")}</Link>
              </p>
            </div>
            <div>
              <h6 class="mb-3  font-semibold text-white text-[18px]  md:justify-start">
                {t("menu-contact")}
              </h6>
              <p class="mb-3 flex items-center justify-center md:justify-center">
                <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                  </svg>
                </span>
                {t("address")}
              </p>
              <Link
                to="tel:+971501926568"
                class="mb-3 flex items-center justify-center md:justify-center"
              >
                <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                +971 50 192 6568
              </Link>
              <Link
                to="tel:+971504750960"
                class="mb-3 flex items-center justify-center md:justify-center"
              >
                <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                +971 50 475 0960
              </Link>
              <div class="flex justify-center  gap-3">
                <Link to="https://t.me/" target="_blank" class="text-white/80 ">
                  <FaTelegram size={26} />
                </Link>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  class="text-white/80 "
                >
                  <FaInstagram size={26} />
                </Link>
                <Link to="https://wa.me/" class="text-white/80 ">
                  <FaWhatsapp size={26} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class=" text-black p-2 bg-white">
          <div class="  text-center gap-3 lg:gap-7 flex items-center flex-wrap pb-14 lg:pb-0 justify-center sm:justify-around">
            <p class="text-center"> {t("copy-right")}</p>
            <a
              href="https://sirir.ir/"
              target="_blank"
              rel="noreferrer"
              dir="ltr"
              className="flex gap-1 items-center"
            >
              Powered By{" "}
              <img
                src="https://sirir.ir/logo.png"
                className="w-8 object-contain items-center mb-1"
                alt="Sirir logo"
              />
              Sirir
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
