import { httpReauest } from "api/api";
import CategoryTitle from "components/CategoryTitle";
import ProductCard from "components/common/ProductCard";
import Footer from "components/Footer";
import Header from "components/header";
import PathShow from "components/PathShow";
import Reveal from "motion/Reveal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const CategoryPage = () => {
  const [t] = useTranslation("global");

  const { name } = useParams();

  const [categorys, setcategorys] = useState([]);
  const [data, setdata] = useState();
  const [products, setproducts] = useState([]);

  useEffect(() => {
    getData();
  }, [name]);

  async function getData() {
    const res = await httpReauest("GET", "/category/" + name, {}, {});
    setcategorys(res.data.data.categories);
    setdata(res.data.data.category);
    const res2 = await httpReauest("GET", "/product?category=" + name, {}, {});
    setproducts(res2.data.data);
  }

  return (
    <>
      <div className="font-[Yekan] mt-[99px] md:mt-[83px]">
        <Header />
        <Swiper dir="ltr" loop modules={[Autoplay]} autoplay={{ delay: 4000 }}>
          <SwiperSlide>
            <Reveal>
              <img
                className="w-full"
                src="/Banner10.jpg"
                alt="EMPORIUM POINT Banner"
              />
            </Reveal>
          </SwiperSlide>
          <SwiperSlide>
            <Reveal>
              <img
                className="w-full"
                src="/Banner11.jpg"
                alt="EMPORIUM POINT Banner"
              />
            </Reveal>
          </SwiperSlide>
        </Swiper>
        <PathShow category={name} />
        <CategoryTitle data={categorys} title={data?.name} />
        <div className="container-xxl py-6 ">
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 my-3">
            {products.map((item) => (
              <Reveal head>
                <ProductCard t={t} data={item} />
              </Reveal>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CategoryPage;
