import Header from "components/header";
import "../App.css";
import Slider from "components/Slider";
import About from "components/About";
import WhyusSection from "components/WhyusSection";
import Services from "components/Services";
import Example from "components/Example";
import Testmonials from "components/Testmonials";
import Team from "components/Team";
import Pricing from "components/Pricing";
import FaqSection from "components/FaqSection";
import Contact from "components/Contact";
import Footer from "components/Footer";
import Projects from "components/Projects";
import { FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { httpReauest } from "api/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Reveal from "motion/Reveal";
import { Helmet } from "react-helmet";

function Home() {
  const [t, i18] = useTranslation("global");
  const [iphones, setiphones] = useState([]);
  const [macks, setmacks] = useState([]);
  const [ipads, setipads] = useState([]);
  const [accessories, setaccessories] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData(params) {
    const res = await httpReauest("GET", "/product?category=iphone", {}, {});
    setiphones(res.data.data);
    const res2 = await httpReauest("GET", "/product?category=mac", {}, {});
    setmacks(res2.data.data);
    const res3 = await httpReauest("GET", "/product?category=ipad", {}, {});
    setipads(res3.data.data);
    const res4 = await httpReauest(
      "GET",
      "/product?category=accessories&sort=createdAt",
      {},
      {}
    );
    setaccessories(res4.data.data);
  }

  return (
    <>
      <Helmet>
        <title>EMPORIUM POINT ELECTRONICS L.L.C</title>
        <meta name="og:title" content="EMPORIUM POINT ELECTRONICS L.L.C" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>{" "}
      <div className=" mt-[99px] md:mt-[83px]">
        <Header />
        <Slider
          data={iphones}
          bgcolor="#e0e0e0"
          img="/Banner3.jpg"
          color="#fff"
        />
        <Slider
          h1={t("classic")}
          desc={t("order-now")}
          data={macks}
          bgcolor="#f5f5f7"
          img="/first.png"
        />
        <Swiper dir="ltr" loop modules={[Autoplay]} autoplay={{ delay: 4000 }}>
          <SwiperSlide>
            <Reveal>
              {i18.language == "fa" ? (
                <img
                  className="w-full"
                  src="/Banner.jpg"
                  alt="EMPORIUM POINT بنر فارسی"
                />
              ) : (
                <img
                  className="w-full"
                  src="/EMPORIUM POINT.png"
                  alt="EMPORIUM POINT"
                />
              )}
            </Reveal>
          </SwiperSlide>
          <SwiperSlide>
            <Reveal>
              <img
                className="w-full"
                src="/Banner5.jpg"
                alt="EMPORIUM POINT Banner"
              />
            </Reveal>
          </SwiperSlide>
          <SwiperSlide>
            <Reveal>
              <img
                className="w-full"
                src="/Banner4.jpg"
                alt="EMPORIUM POINT Banner"
              />
            </Reveal>
          </SwiperSlide>
        </Swiper>

        <Slider
          bgcolor="#000"
          data={ipads}
          img="/iPad-Pro.png"
          color="#fff"
          h1={t("classic")}
          desc={t("order-now")}
        />
        <Swiper dir="ltr" loop modules={[Autoplay]} autoplay={{ delay: 4000 }}>
          <SwiperSlide>
            <Reveal>
              <img
                className="w-full"
                src="/Your paragraph text (2).png"
                alt="EMPORIUM POINT Banner"
              />
            </Reveal>
          </SwiperSlide>
          <SwiperSlide>
            <Reveal>
              <img
                className="w-full"
                src="/Banner8.jpg"
                alt="EMPORIUM POINT Banner"
              />
            </Reveal>
          </SwiperSlide>
          <SwiperSlide>
            <Reveal>
              <img
                className="w-full"
                src="/Banner9.jpg"
                alt="EMPORIUM POINT Banner"
              />
            </Reveal>
          </SwiperSlide>
        </Swiper>

        <Slider
          bgcolor="#f5f5f7"
          data={accessories}
          img="/apple_watch_series_10.png"
          color="#000"
          h1={t("classic")}
          desc={t("order-now")}
        />
        <div className="relative">
          <Reveal>
            <h3 className="absolute left-1/2 top-[40px] w-full lg:w-fit lg:top-[60px] -translate-x-1/2 -translate-y-1/2 py-2 px-8 font-bold text-center lg:rounded-[8px] bg-[#000]/70 text-white text-[22px] lg:text-[30px]">
              {t("our-office")}
            </h3>
            <p className="absolute left-1/2 top-[85px] w-full lg:w-fit lg:top-[120px] -translate-x-1/2 -translate-y-1/2 py-2 px-8 lg:rounded-[8px] text-center bg-[#fff]/70 text-black text-[14px] lg:text-[18px]">
              {t("address")}
            </p>
            <img
              src="/office.jpg"
              className="w-full"
              alt="EMPORIUM POINT Office"
            />
          </Reveal>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
