import { CDN_BASE_URL } from "config";
import React from "react";
import { Link } from "react-router-dom";
import { priceComma } from "utils/priceComma";

const ProductCard = ({ t, data }) => {
  return (
    <Link
      to={`/product/${data?.url}`}
      className="rounded-[12px] border bg-white p-2 block"
    >
      <div className="flex flex-col gap-2 hover_btn">
        <img
          src={CDN_BASE_URL + data?.images[0]}
          className="w-full object-contain rounded-[8px] h-[175px] md:h-[250px]"
          alt="Product"
        />
        <h3 className="font-bold text-[20px] text-black text-center">
          {data?.name}
        </h3>
        {/* {data?.minPrice ? (
          <p className="text-[16px] font-bold text-center text-black/90">
            {data?.minPrice == data?.maxPrice
              ? priceComma(data?.minPrice, 2)
              : `${priceComma(data?.minPrice, 2)}-${priceComma(
                  data?.maxPrice,
                  2
                )}`}
            {t("toman")}
          </p>
        ) : null} */}

        <button className="block w-full bg-[#F5F5F7] p-2 text-center rounded-[8px] text-black duration-200">
          {t("about-btn")}
        </button>
      </div>
    </Link>
  );
};

export default ProductCard;
